/* You can add global styles to this file, and also import other style files */
.align {
  text-align: center;
}

.icon-style {
  font-size: 1.25rem;
  color: #D8365D;
}

.menu-mobile{
  display: none;
}
.flex-menu{
  // display: flex;
  justify-content: space-around;
}

@media only screen and (max-width: 600px) {

  .menu-mobile{
      display: block;
  }
  .menu-desktop{
      display: none;
  }

}

.dropdown-menu {
font-size: 1rem;
color: #212529;
text-align: center;
list-style: none;
}
.custom-table {
  background-color: #E8E8E8;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgb(0 0 0 / 5%);
}

a{
  cursor: pointer;
}
div.activity-content{
  cursor:pointer
}
.table th img.sorticon {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}
